import { Link } from "react-router-dom";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="logo-and-name">
            <Link to="/">
              <img src="/img/logo.png" alt="logo" className="logo" />
            </Link>
            <Link className="navbar-brand page-scroll" to="/">
              K16 Tennis
            </Link>{" "}
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/" className="page-scroll">
                <a href="#">Početna</a>
              </Link>
            </li>
            <li>
              <Link to="/spanovi" className="page-scroll">
                <a href="#">Španovi</a>
              </Link>
            </li>
            <li>
              <Link to="/gripovi" className="page-scroll">
                <a href="#">Gripovi</a>
              </Link>
            </li>
            <li>
              <Link to="/turniri" className="page-scroll">
                <a href="#">Turniri</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
