import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/header.css";
import { Carousel } from "react-responsive-carousel";

import naslovna1 from "../assets/img/naslovna1.jpeg";
import naslovna2 from "../assets/img/naslovna2.jpeg";
import naslovna3 from "../assets/img/naslovna3.png";

import ikonicaOff from "../assets/img/ikonica-off.png";
import ikonicaOn from "../assets/img/ikonica-on.png";

export const Header = (props) => {
  // return null;
  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      showArrows={false}
      showThumbs={false}
      stopOnHover={true}
      renderIndicator={(clickHandler, isSelected, index, label) => (
        <div
          className="loptica-container"
          onClick={() => {
            console.log("KLIKNO IBOGAMI");
            clickHandler();
          }}
        >
          <img
            className="loptica"
            alt="loptica"
            src={isSelected ? ikonicaOn : ikonicaOff}
          />
        </div>
      )}
    >
      <div>
        <img alt="1" src={naslovna1} />
      </div>
      <div>
        <img alt="2" src={naslovna2} />
      </div>
      <div>
        <img alt="3" src={naslovna3} />
      </div>
    </Carousel>
  );
};
