import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Spanovi from "./pages/Spanovi";
import SpanDetalji from "./pages/SpanDetalji";
import Turniri from "./pages/Turniri";
import { useLocation } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import Gripovi from "./pages/Gripovi";

function App() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const menu = document.querySelector("div#bs-example-navbar-collapse-1");
    menu.className = "navbar-collapse collapse";
  }, [pathname]);

  const [landingPageData, setLandingPageData] = React.useState({});
  React.useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/spanovi" element={<Spanovi />} />
        <Route path="/spanovi/:spanId" element={<SpanDetalji />} />
        <Route path="/gripovi" element={<Gripovi />} />
        <Route path="/turniri" element={<Turniri />} />
      </Routes>
      <Contact data={landingPageData.Contact} />
    </div>
  );
}

export default App;
