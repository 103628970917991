import { Link } from "react-router-dom";
import gripovi from "../data/gripovi.json";
import overgripovi from "../data/overgripovi.json";

const Grip = ({ grip, index }) => {
  return (
    <div className="col-md-3 col-xs-12">
      <div className="card">
        <img src={"/img/gripovi/" + grip.image} alt="Avatar" />
        <div className="card-container">
          <div className="span-name">
            <h4>
              <b>
                {grip.name}
              </b>
            </h4>
          </div>

          <div className="span-price-row">
            <div>
              <b>Cijena</b>
            </div>
            <div className="span-price">
              {grip.price}KM/kom
            </div>
          </div>
        
          
          <br />
         
        </div>
      </div>
    </div>
  );
};

const Gripovi = () => {
  return (
    <div>
      <div id="spanovi">
        <div className="container">
          <h2>Osnovni gripovi</h2>
         
          
          <div className="row">
            {gripovi
              .sort((a, b) => (a.price[0] < b.price[0] ? 1 : -1))
              .map((grip, index) => (
                <Grip key={index} index={index} grip={grip} />
              ))}
          </div>
          <h2>Overgripovi</h2>
          <div className="row">
            {overgripovi
              .sort((a, b) => (a.price[0] < b.price[0] ? 1 : -1))
              .map((grip, index) => (
                <Grip key={index} index={index} grip={grip} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gripovi;
