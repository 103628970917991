const Turniri = ({ title = "Turniri" }) => {
  return (
    <div>
      <div id="spanovi">
        <div className="container">
          <h2>{title}</h2>
          <div className="turniri">
            <img src="/img/ComingSoon.png" alt="ComingSoon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turniri;
