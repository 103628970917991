import { Link } from "react-router-dom";
import spanovanje from "../assets/img/spanovanje.png";
import zica from "../assets/img/zica.png";
import grip from "../assets/img/grip.png";
import turnir from "../assets/img/turnir.png";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Usluge</h2>
        </div>
        <div className="row features-row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <Link to="/spanovi">
              <img alt="alt" className="features-slika" src={spanovanje} />
              <h3>Španovanje i Žice</h3>
              <p>
                Najnovije tehnike španovanja reketa sa velikim izborom žica
                raznih brendova
              </p>
            </Link>
          </div>
          <div className="col-xs-12 col-md-4">
            {" "}
            <Link to="/gripovi">
              <img alt="alt" className="features-slika" src={grip} />
              <h3>Gripovi</h3>
              <p>Gripovi različite debljine i funkcionalnosti</p>
            </Link>
          </div>
          <div className="col-xs-12 col-md-4">
            {" "}
            <Link to="/turniri">
              <img alt="alt" className="features-slika" src={turnir} />
              <h3>Turniri</h3>
              <p>Obavijesti o nadolazećim turnirima</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
